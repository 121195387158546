import { api } from '@/api';
import { ActionContext } from 'vuex';
import {
  IUserProfileCreate,
  IUserProfileUpdate,
  IUUsersDocuments, ICreateEfectivo
} from '@/interfaces';
import { State } from '../state';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
  commitSetUsers,
  commitSetUser,
  commitSetDocumentos,
  commitSetDocumentosCategorias,
  commitSetDocumentosTipos,
  commitSetDocumento,
  commitSetDocumentosUsuarios,
  commitSetCategoria,
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import {
  commitAddNotification,
  commitRemoveNotification,
} from '../main/mutations';

type MainContext = ActionContext<AdminState, State>;

export const actions = {
  async actionGetUsers(context: MainContext) {
    try {
      const response = await api.getUsers(context.rootState.main.token);
      if (response) {
        commitSetUsers(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUser(
    context: MainContext,
    payload: { id: number; user: IUserProfileUpdate },
  ) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateUser(
            context.rootState.main.token,
            payload.id,
            payload.user,
          ),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500),
          ),
        ])
      )[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'User successfully updated',
        color: 'success',
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createUser(context.rootState.main.token, payload),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500),
          ),
        ])
      )[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'User successfully created',
        color: 'success',
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteUser(context: MainContext, idUsuario: number) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.deleteUser(context.rootState.main.token, idUsuario),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500),
          ),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'El usuario se ha borrado correctamente',
        color: 'success',
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionGetDocumentos(context: MainContext) {
    try {
      const response = await api.getDocuments(context.rootState.main.token);
      if (response) {
        commitSetDocumentos(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetDocumentosUsuarios(context: MainContext) {
    try {
      const response = await api.getDocumentsUsuarios(
        context.rootState.main.token,
      );
      if (response) {
        commitSetDocumentosUsuarios(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetCategorias(context: MainContext) {
    try {
      const response = await api.getCategorias(context.rootState.main.token);
      if (response) {
        commitSetDocumentosCategorias(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetTipos(context: MainContext) {
    try {
      const response = await api.getTipos(context.rootState.main.token);
      if (response) {
        commitSetDocumentosTipos(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateDocumento(
    context: MainContext,
    payload: { id: number; documento: any },
  ) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.createDocumento(
        context.rootState.main.token,
        payload.documento,
      );

      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'El Documento se ha creado',
        color: 'success',
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateDocumento(
    context: MainContext,
    payload: { id: number; documento: any },
  ) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateDocumento(
            context.rootState.main.token,
            payload.id,
            payload.documento,
          ),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500),
          ),
        ])
      )[0];
      commitSetDocumento(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'El Documento se ha actualizado',
        color: 'success',
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteDocumento(context: MainContext, idUsuario: number) {
    try {
      const loadingNotification = { content: 'guardando', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.deleteDocumento(context.rootState.main.token, idUsuario),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500),
          ),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'El documento se ha borrado correctamente',
        color: 'success',
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionSincronizar(context: MainContext) {
    try {
      const loadingNotification = {
        content: 'sincronizando',
        showProgress: true,
      };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.sincronizar(context.rootState.main.token),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500),
          ),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content:
          'Se han añadido ' +
          response.data.length +
          ' documentos a la base de datos.',
        color: 'success',
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAsignarDocumentos(
    context: MainContext,
    payload: IUUsersDocuments,
  ) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateUserDocumentos(context.rootState.main.token, payload),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500),
          ),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Los cambios se han guardado',
        color: 'success',
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateCategoria(
    context: MainContext,
    payload: { id: number; categoria: string; alias: string },
  ) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateCategoria(
            context.rootState.main.token,
            payload.id,
            payload.categoria,
            payload.alias,
          ),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500),
          ),
        ])
      )[0];
      commitSetCategoria(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Categoría actualizada',
        color: 'success',
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateCategoria(
    context: MainContext,
    payload: {
      categoria: string;
      alias: string;
    },
  ) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createCategoria(
            context.rootState.main.token,
            payload.categoria,
            payload.alias,
          ),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500),
          ),
        ])
      )[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'User successfully created',
        color: 'success',
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateCobroEfectivo(
    context: MainContext,
    payload: ICreateEfectivo,
  ) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.createCobroEfectivo(
        context.rootState.main.token,
        payload,
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'El Cobro se ha creado',
        color: 'success',
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetCobroEfectivo(context: MainContext) {
    try {
      const response = await api.getCobroEfectivo(context.rootState.main.token);
      if (response) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetOneCobroEfectivo(context: MainContext, id: number) {
    try {
      const response = await api.getOneCobroEfectivo(context.rootState.main.token, id);
      if (response) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateCobroEfectivo(
    context: MainContext,
    payload: ICreateEfectivo,
  ) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.updateCobroEfectivo(
        context.rootState.main.token, payload.id!,
        payload,
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'El Cobro se ha modificado',
        color: 'success',
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteCobroEfectivo(context: MainContext, id: number) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.deleteCobroEfectivo(context.rootState.main.token, id),
          await new Promise((resolve, reject) =>
            setTimeout(() => resolve(), 500),
          ),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'El cobro se ha borrado correctamente',
        color: 'success',
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

};

const { dispatch } = getStoreAccessors<AdminState, State>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchDeleteUser = dispatch(actions.actionDeleteUser);

// Documentos
export const dispatchGetDocumentos = dispatch(actions.actionGetDocumentos);
export const dispatchGetDocumentosCategorias = dispatch(
  actions.actionGetCategorias,
);
export const dispatchGetDocumentosUsuarios = dispatch(
  actions.actionGetDocumentosUsuarios,
);

export const dispatchGetDocumentosTipos = dispatch(actions.actionGetTipos);
export const dispatchCreateDocumento = dispatch(actions.actionCreateDocumento);

export const dispatchUpdateDocumento = dispatch(actions.actionUpdateDocumento);
export const dispatchDeleteDocumento = dispatch(actions.actionDeleteDocumento);
export const dispatchSincronizar = dispatch(actions.actionSincronizar);
export const dispatchAsignarDocumentos = dispatch(
  actions.actionAsignarDocumentos,
);

export const dispatchUpdateCategoria = dispatch(actions.actionUpdateCategoria);
export const dispatchCreateDocumentoCategoria = dispatch(
  actions.actionCreateCategoria,
);


export const dispatchCreateCobroEfectivo = dispatch(actions.actionCreateCobroEfectivo);
export const dispatchGetCobrosEfectivo = dispatch(actions.actionGetCobroEfectivo);
export const dispatchGetOneCobrosEfectivo = dispatch(actions.actionGetOneCobroEfectivo);
export const dispatchUpdateCobroEfectivo = dispatch(actions.actionUpdateCobroEfectivo);
export const dispatchDeleteCobroEfectivo = dispatch(actions.actionDeleteCobroEfectivo);


