import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () =>
        import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [
        {
          path: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'recover-password',
          component: () =>
            import(
              /* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'
            ),
        },
        {
          path: 'reset-password',
          component: () =>
            import(
              /* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'
            ),
        },
        {
          path: 'main',
          component: () =>
            import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'dashboard',
              component: () =>
                import(
                  /* webpackChunkName: "main-dashboard" */ './views/main/Dashboard.vue'
                ),
            },
            {
              path: 'profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue'
                    ),
                },
                {
                  path: 'edit',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-edit" */ './views/main/profile/UserProfileEdit.vue'
                    ),
                },
                {
                  path: 'password',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-password" */ './views/main/profile/UserProfileEditPassword.vue'
                    ),
                },
              ],
            },
            {
              path: 'admin',
              component: () =>
                import(
                  /* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'
                ),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'documentos',
                  redirect: 'documentos/all',
                },
                {
                  path: 'users/all',
                  meta: 'usuarios',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue'
                    ),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  meta: 'usuarios',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/EditUser.vue'
                    ),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  meta: 'usuarios',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/CreateUser.vue'
                    ),
                },
                {
                  path: 'documentos/all',
                  meta: 'documentos',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-documentos" */ './views/main/admin/AdminDocumentos.vue'
                    ),
                },
                {
                  path: 'documentos/edit/:id',
                  meta: 'documentos',
                  name: 'main-admin-documentos-edit',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-documentos-edit" */ './views/main/admin/EditDocumento.vue'
                    ),
                },
                {
                  path: 'documentos/create',
                  meta: 'documentos',
                  name: 'main-admin-documentos-create',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-documentos-create" */ './views/main/admin/CreateDocumento.vue'
                    ),
                },
                {
                  path: 'documentos/asignar',
                  meta: 'documentos',
                  name: 'main-admin-documentos-asignar',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-documentos-asignar" */ './views/main/admin/AdminDocumentosAsignar.vue'
                    ),
                },
                {
                  path: 'documentos/categorias',
                  meta: 'documentos',
                  name: 'main-admin-documentos-categorias',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-documentos-categorias" */ './views/main/admin/AdminDocumentosCategorias.vue'
                    ),
                },
                {
                  path: 'documentos/categoria/edit/:id',
                  meta: 'documentos',
                  name: 'main-admin-documentos-categoria-edit',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-documentos-categoria-edit" */ './views/main/admin/EditDocumentoCategoria.vue'
                    ),
                },
                {
                  path: 'documentos/categoria/create',
                  meta: 'documentos',
                  name: 'main-admin-documentos-categoria-create',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-documentos-categoria-create" */ './views/main/admin/CreateDocumentoCategoria.vue'
                    ),
                }
                ,
                {
                  path: 'cobrosefectivo/all',
                  meta: 'cobrosefectivo',
                  name: 'main-admin-cobrosefectivo-all',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-cobrosefectivo-all" */ './views/main/admin/CobrosEfectivoList.vue'
                    ),
                }, {
                  path: 'cobrosefectivo/create',
                  meta: 'cobrosefectivo',
                  name: 'main-admin-cobrosefectivo-create',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-cobrosefectivo-create" */ './views/main/admin/CobrosEfectivoCreate.vue'
                    ),
                },
                {
                  path: 'cobrosefectivo/edit/:id',
                  name: 'main-admin-cobrosefectivo-edit',
                  meta: 'usuarios',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/CobrosEfectivoEdit.vue'
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/*',
      redirect: '/',
    },
  ],
});
