import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    adminUsers: (state: AdminState) => state.users,
    adminOneUser: (state: AdminState) => (userId: number) => {
        const filteredUsers = state.users.filter((user) => user.id === userId);
        if (filteredUsers.length > 0) {
            return { ...filteredUsers[0] };
        }
    },
    adminDocumentos: (state: AdminState) => state.documentos,
    adminDocumentosUsuarios: (state: AdminState) => state.users_documentos,

    adminOneDocumento: (state: AdminState) => (userId: number) => {
        const filteredUsers = state.documentos.filter((documento) => documento.id === userId);
        if (filteredUsers.length > 0) {
            return { ...filteredUsers[0] };
        }
    },
    adminOneCategoria: (state: AdminState) => (categoriaId: number) => {
        const filteredUsers = state.documentos_categorias.filter((categoria) => categoria.id === categoriaId);
        if (filteredUsers.length > 0) {
            return { ...filteredUsers[0] };
        }
    },
    adminDocumentoCategoria: (state: AdminState) => state.documentos_categorias,
    adminDocumentoTipo: (state: AdminState) => state.documentos_tipos,

};

const { read } = getStoreAccessors<AdminState, State>('');


export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);

export const readAdminDocuments = read(getters.adminDocumentos);
export const readAdminDocumentsUsuarios = read(getters.adminDocumentosUsuarios);
export const readAdminOneDocumento = read(getters.adminOneDocumento);
export const readAdminOneCategoria = read(getters.adminOneCategoria);

export const readAdminDocumentoCategorias = read(getters.adminDocumentoCategoria);
export const readAdminDocumentoTipos = read(getters.adminDocumentoTipo);
