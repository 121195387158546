import Vue from 'vue';
import Vuetify from 'vuetify';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import es from 'vuetify/src/locale/es';
import en from 'vuetify/src/locale/en';


Vue.use(Vuetify);

const opts = {

  lang: {
    locales: {
      es,
      en,
    },
    current: 'es',
  },
  theme: {
    themes: {
      light: {
        primary: '#d90303',
        secondary: '#444',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
    },
  },
};

export default new Vuetify(opts);
