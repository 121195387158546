import axios from 'axios';
import { apiUrl } from '@/env';
import {
  IUserProfile,
  IUDocumentosCategorias,
  IUDocumentosTipos,
  IUserProfileUpdate,
  IUserProfileCreate,
  IUDocumentosProfile,
  IUDocumentosProfileUpdate,
  IUUsersWithDocument,
  IUUsersDocuments,
  IUUserWithDocument,
  IUOtherUsersCodes, ICreateEfectivo, ICreateEfectivoAll
} from './interfaces';

function authHeaders(token: string, tipo = '') {
  const ret = { headers: { Authorization: `Bearer ${token}` }, responseType: '' };
  if (tipo === 'file') {
    ret.headers['Content-Type'] = 'multipart/form-data';
  } else if (tipo === 'pdf') {
    ret.responseType = 'arraybuffer';

  }

  return ret;
}

export const api = {
  async logInGetToken(
    username: string,
    password: string,
    nCliente: string,
    tipo: string
  ) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    params.append('nCliente', nCliente);
    params.append('tipo', tipo);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async changeLogin(nCliente: string, tipo: string, token: string) {
    const params = new URLSearchParams();
    params.append('nCliente', nCliente);
    params.append('tipo', tipo);
    return axios.post(
      `${apiUrl}/api/v1/users/change_user`,
      params,
      authHeaders(token)
    );
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(
      `${apiUrl}/api/v1/users/me`,
      authHeaders(token)
    );
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(
      `${apiUrl}/api/v1/users/me`,
      data,
      authHeaders(token)
    );
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(
      `${apiUrl}/api/v1/users/`,
      authHeaders(token)
    );
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(
      `${apiUrl}/api/v1/users/${userId}`,
      data,
      authHeaders(token)
    );
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async passwordRecoveryNcliente(nCliente: string, tipo: string) {
    return axios.post(
      `${apiUrl}/api/v1/password-recovery-ncliente/${nCliente}/tipo/${tipo}`
    );
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token
    });
  },
  async deleteUser(token: string, idUsuario: number) {
    return axios.delete(
      `${apiUrl}/api/v1/users/${idUsuario}`,
      authHeaders(token)
    );
  },

  // Documentos
  async getDocumentsBuscar(token: string, src: string) {
    return axios.get<IUDocumentosProfile[]>(
      `${apiUrl}/api/v1/documentos/search/?query=` + src,
      authHeaders(token)
    );
  },
async getDocumentsUserBuscar(token: string, user: number) {
    return axios.get<IUUsersWithDocument>(
      `${apiUrl}/api/v1/users/documentos/search/user/` + user,
      authHeaders(token)
    );
  },

  async getDocumentsUsuariosBuscar(token: string, src: string) {
    return axios.get<IUUsersWithDocument[]>(
      `${apiUrl}/api/v1/users/documentos/search/?query=` + src,
      authHeaders(token)
    );
  },
  async getDocuments(token: string) {
    return axios.get<IUDocumentosProfile[]>(
      `${apiUrl}/api/v1/documentos/?skip=0&limit=100000`,
      authHeaders(token)
    );
  },
  async getDocumentsUsuarios(token: string) {
    return axios.get<IUUsersWithDocument[]>(
      `${apiUrl}/api/v1/users/documentos`,
      authHeaders(token)
    );
  },
  async getDocumentsUsuario(token: string) {
    return axios.get<IUUserWithDocument>(
      `${apiUrl}/api/v1/users/documentos/me`,
      authHeaders(token)
    );
  },
  async getCategorias(token: string) {
    return axios.get<IUDocumentosCategorias[]>(
      `${apiUrl}/api/v1/categorias/`,
      authHeaders(token)
    );
  },
  async getTipos(token: string) {
    return axios.get<IUDocumentosTipos[]>(
      `${apiUrl}/api/v1/tipos_archivo/`,
      authHeaders(token)
    );
  },
  async updateDocumento(
    token: string,
    documentoId: number,
    data: IUDocumentosProfileUpdate
  ) {
    return axios.put(
      `${apiUrl}/api/v1/documentos/${documentoId}`,
      data,
      authHeaders(token, 'file')
    );
  },
  async createDocumento(token: string, data: IUDocumentosProfileUpdate) {
    return axios.post(
      `${apiUrl}/api/v1/documentos/`,
      data,
      authHeaders(token, 'file')
    );
  },
  async deleteDocumento(token: string, idDocumento: number) {
    return axios.delete(
      `${apiUrl}/api/v1/documentos/${idDocumento}`,
      authHeaders(token)
    );
  },
  async sincronizar(token: string) {
    return axios.post(
      `${apiUrl}/api/v1/documentos/procesa_documentos_bucket`,
      authHeaders(token)
    );
  },
  async updateUserDocumentos(token: string, data: IUUsersDocuments) {
    return axios.post(
      `${apiUrl}/api/v1/documentos/documentos_usuarios/${data.user_id}`,
      data.id_documentos,
      authHeaders(token)
    );
  },

  async updateCategoria(
    token: string,
    categoriaId: number,
    categoria: string,
    alias: string
  ) {
    return axios.put(
      `${apiUrl}/api/v1/categorias/${categoriaId}`,
      { categoria, alias },
      authHeaders(token)
    );
  },
  async createCategoria(token: string, categoria: string, alias: string) {
    return axios.post(
      `${apiUrl}/api/v1/categorias/`,
      { categoria, alias },
      authHeaders(token)
    );
  },
  async getSpace(token: string) {
    return axios.get(`${apiUrl}/api/v1/utils/bucketsize/`, authHeaders(token));
  },
  async getOtherUsersCodes(token: string) {
    return axios.get<IUOtherUsersCodes>(
      `${apiUrl}/api/v1/users/other_users_code`,
      authHeaders(token)
    );
  },


  async createCobroEfectivo(token: string, data: ICreateEfectivo) {
    return axios.post(
      `${apiUrl}/api/v1/cobroefectivo/`,
      data,
      authHeaders(token, 'file')
    );
  },
  async getCobroEfectivo(token: string) {
    return axios.get<ICreateEfectivoAll[]>(
      `${apiUrl}/api/v1/cobroefectivo/`,
      authHeaders(token)
    );
  },

  async getOneCobroEfectivo(token: string, id: number) {
    return axios.get<ICreateEfectivoAll>(
      `${apiUrl}/api/v1/cobroefectivo/${id}`,
      authHeaders(token)
    );
  },
  async updateCobroEfectivo(token: string, Id: number, data: ICreateEfectivo) {
    return axios.put(
      `${apiUrl}/api/v1/cobroefectivo/${Id}`,
      data,
      authHeaders(token)
    );
  },
  async deleteCobroEfectivo(token: string, idUsuario: number) {
    return axios.delete(
      `${apiUrl}/api/v1/cobroefectivo/${idUsuario}`,
      authHeaders(token)
    );
  },

  async getCobroEfectivoQr(token: string, id: number) {
    return axios.get(
      `${apiUrl}/api/v1/cobroefectivo/qr/${id}`,
      authHeaders(token)
    );
  },
  async getCobroEfectivoPdf(token: string, id: number) {
    return axios.get(
      `${apiUrl}/api/v1/cobroefectivo/signaturit/document/${id}/1`,
      authHeaders(token, 'pdf')
    );
  },
  async getCobroEfectivoPdfCert(token: string, id: number) {
    return axios.get(
      `${apiUrl}/api/v1/cobroefectivo/signaturit/document/${id}/2`,
      authHeaders(token, 'pdf')
    );
  },

};
