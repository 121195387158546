import {
  IUserProfile,
  IUDocumentosProfile,
  IUDocumentosCategorias,
  IUDocumentosTipos,
  IUUsersWithDocument,
} from '@/interfaces';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
  setUsers(state: AdminState, payload: IUserProfile[]) {
    state.users = payload;
  },
  setUser(state: AdminState, payload: IUserProfile) {
    const users = state.users.filter(
      (user: IUserProfile) => user.id !== payload.id,
    );
    users.push(payload);
    state.users = users;
  },

  setDocumentos(state: AdminState, payload: IUDocumentosProfile[]) {
    state.documentos = payload;
  },
  setDocumentosUsuarios(state: AdminState, payload: IUUsersWithDocument[]) {
    state.users_documentos = payload;
  },
  setDocumento(state: AdminState, payload: IUDocumentosProfile) {
    const documentos = state.documentos.filter(
      (documento: IUDocumentosProfile) => documento.id !== payload.id,
    );
    documentos.push(payload);
    state.documentos = documentos;
  },

  setDocumentosCategorias(
    state: AdminState,
    payload: IUDocumentosCategorias[],
  ) {
    state.documentos_categorias = payload;
  },
  setDocumentosTipos(state: AdminState, payload: IUDocumentosTipos[]) {
    state.documentos_tipos = payload;
  },
  setCategoria(state: AdminState, payload: IUDocumentosCategorias) {
    const categorias = state.documentos_categorias.filter(
      (categoria: IUDocumentosCategorias) => categoria.id !== payload.id,
    );
    categorias.push(payload);
    state.documentos_categorias = categorias;
  },
};

const { commit } = getStoreAccessors<AdminState, State>('');

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);
export const commitSetDocumentos = commit(mutations.setDocumentos);
export const commitSetDocumentosUsuarios = commit(mutations.setDocumentosUsuarios);

export const commitSetDocumento = commit(mutations.setDocumento);
export const commitSetDocumentosCategorias = commit(
  mutations.setDocumentosCategorias,
);
export const commitSetDocumentosTipos = commit(mutations.setDocumentosTipos);
export const commitSetCategoria = commit(mutations.setCategoria);
